import React, { useState } from "react";

import clsx from "clsx";
import { BsFilter } from "react-icons/bs";

type FilterWrapperProps = {
  children: React.ReactNode;
  filtersClassName?: string;
};

const FilterWrapper = ({ children, filtersClassName }: FilterWrapperProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className={clsx("w-full", "flex flex-col", "select-none")}>
      <div className={clsx("w-full mt-2 py-4", "flex justify-start", "gap-x-2")}>
        <div
          className="flex items-center justify-center gap-x-1 cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          <BsFilter size={28} />
          <div className="text-examedi-gray-2 text-md text-left">
            {isOpen ? "Cerrar filtros" : "Aplicar más filtros"}
          </div>
        </div>
      </div>
      <div className={clsx("w-full hidden", isOpen && ["!grid", filtersClassName])}>{children}</div>
    </div>
  );
};

export default FilterWrapper;
