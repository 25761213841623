import React, { useState, useEffect } from "react";

import { Switch } from "@material-ui/core";
import clsx from "clsx";

import {
  FingerprintAvailableRadio,
  SectorSelect,
  RegionSelect,
  MedicalActionsSelect,
  MedicalServicesSelect,
  Heatmap,
  FilterWrapper,
} from "~/components/Capacity";
import NurseSpecialtiesSelect from "~/components/Capacity/NurseSpecialtiesSelect";
import Tabs from "~/components/Common/Tabs";
import InputDate from "~/components/inputs/InputDate";
import { medicalActionAPI } from "~/utils/api/v2";
import { listMedicalServices } from "~/utils/api/v2/items";
import { listNurseSpecialties } from "~/utils/api/v2/nurse";
import { CODE_TO_COUNTRY, COUNTRY_CODES } from "~/utils/data/constants";
import type { Locale } from "~/utils/interfaces/Locale";
import { NurseSpecialty } from "~/utils/interfaces/Nurse";
import type { Sector } from "~/utils/interfaces/Sectors";
import type { MedicalAction, Item } from "~/utils/interfaces/Services";

const FINGERPRINT_MAPPER: Record<string, boolean | null> = {
  Ambos: null,
  Si: true,
  No: false,
};

const CapacityPage = () => {
  const [country, setCountry] = useState<Locale>(COUNTRY_CODES[0]);
  const [selectedRegion, setSelectedRegion] = useState<string>("Capital");
  const [fingerprintAvailable, setFingerprintAvailable] = useState<string>(Object.keys(FINGERPRINT_MAPPER)[0]);
  const [selectedSector, setSelectedSector] = useState<Sector | null>(null);
  const [allMedicalActions, setAllMedicalActions] = useState<MedicalAction[]>([]);
  const [allMedicalServices, setAllMedicalServices] = useState<Item[]>([]);
  const [selectedMedicalActions, setSelectedMedicalActions] = useState<MedicalAction[]>([]);
  const [selectedMedicalServices, setSelectedMedicalServices] = useState<Item[]>([]);
  const [allNurseSpecialties, setAllNurseSpecialties] = useState<NurseSpecialty[]>([]);
  const [selectedNurseSpecialties, setSelectedNurseSpecialties] = useState<NurseSpecialty[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filterByDate, setFilterByDate] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string>("");

  const handleTabChange = () => {
    setSelectedSector(null);
    setFingerprintAvailable(Object.keys(FINGERPRINT_MAPPER)[0]);
    setSelectedRegion("Capital");
  };

  useEffect(() => {
    const fetchMedicalActions = async () => {
      if (!country) return;

      setIsLoading(true);
      const [medicalServices, medicalActions, nurseSpecialties] = await Promise.all([
        listMedicalServices(country),
        medicalActionAPI.list(country),
        listNurseSpecialties(),
      ]);
      setAllMedicalActions(medicalActions.data || []);
      setAllMedicalServices(medicalServices || []);
      setAllNurseSpecialties(nurseSpecialties || []);
      setIsLoading(false);
    };
    fetchMedicalActions();
  }, [country]);

  return (
    <div
      className={clsx(
        "w-full",
        "px-5 py-4",
        "flex flex-col gap-y-5 bg-examedi-white-pure",
        "rounded-lg border border-examedi-gray-4",
      )}
    >
      <h1 className="text-4xl font-light">Disponibilidad de HTs por sector</h1>
      <Tabs
        elements={COUNTRY_CODES}
        parser={(value: string) => CODE_TO_COUNTRY[value as Locale]}
        onSelectTab={(value: string) => setCountry(value as Locale)}
        onTabChange={handleTabChange}
      >
        <div className="font-bold mb-3">
          Filtrar por sector
          <Switch
            onChange={(e) => setFilterByDate(e.target.checked)}
            value={filterByDate}
          />
          Filtrar por día
        </div>
        {!filterByDate && (
          <div className={clsx("w-full", "grid grid-cols-12 gap-x-6 gap-y-10")}>
            <RegionSelect
              className="col-span-4"
              country={country}
              currentRegion={selectedRegion}
              onSetRegion={(value: string) => setSelectedRegion(value)}
            />
            <div className={clsx("col-span-8", "flex items-end justify-start")}>
              <SectorSelect
                country={country}
                selectedRegion={selectedRegion}
                selectedSector={selectedSector}
                onSetSelectedSector={(value: Sector | null) => setSelectedSector(value)}
              />
            </div>
          </div>
        )}
        {filterByDate && (
          <InputDate
            id="perSectorDateSelector"
            name="perSectorDateSelector"
            placeholder="Selecciona una fecha"
            onChange={(e) => setSelectedDate(e.target.value)}
            value={selectedDate}
            className="!w-48"
          />
        )}
        <FilterWrapper filtersClassName="!grid grid-cols-12 gap-x-6">
          <div className="flex flex-col col-span-4 gap-y-2">
            <FingerprintAvailableRadio
              selectedOption={country === "mx" ? "No" : fingerprintAvailable}
              options={Object.keys(FINGERPRINT_MAPPER)}
              disabled={country === "mx"}
              onSetOption={(value: string) => setFingerprintAvailable(value)}
            />
            <NurseSpecialtiesSelect
              selectedNurseSpecialties={selectedNurseSpecialties}
              allNurseSpecialties={allNurseSpecialties}
              isLoading={isLoading}
              onSetNurseSpecialties={(values: NurseSpecialty[]) => setSelectedNurseSpecialties(values)}
            />
          </div>
          <MedicalActionsSelect
            className="col-span-4"
            selectedMedicalActions={selectedMedicalActions}
            allMedicalActions={allMedicalActions}
            isLoading={isLoading}
            onSetMedicalActions={(values: MedicalAction[]) => setSelectedMedicalActions(values)}
          />
          <MedicalServicesSelect
            className="col-span-4"
            selectedMedicalServices={selectedMedicalServices}
            allMedicalServices={allMedicalServices}
            isLoading={isLoading}
            onSetMedicalServices={(values: Item[]) => setSelectedMedicalServices(values)}
          />
        </FilterWrapper>
        {((selectedSector && !filterByDate) || (filterByDate && !!selectedDate)) && (
          <Heatmap
            sectorId={selectedSector ? selectedSector.id : null}
            country={country}
            fingerprintAvailable={FINGERPRINT_MAPPER[fingerprintAvailable]}
            medicalActionIds={selectedMedicalActions.map(({ id }) => id)}
            medicalServiceIds={selectedMedicalServices.map(({ id }) => id)}
            nurseSpecialtiesIds={selectedNurseSpecialties.map(({ id }) => id)}
            filterByDate={filterByDate}
            selectedDate={selectedDate}
          />
        )}
      </Tabs>
    </div>
  );
};

export default CapacityPage;
