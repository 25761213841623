import React, { useEffect, useState } from "react";

import clsx from "clsx";

import { CheckboxesSkeletonLoader } from "~/components/Common/SkeletonLoader";
import type { NurseSpecialty } from "~/utils/interfaces/Nurse";

type NurseSpecialtiesSelectProps = {
  className?: string;
  selectedNurseSpecialties: NurseSpecialty[];
  allNurseSpecialties: NurseSpecialty[];
  isLoading?: boolean;
  onSetNurseSpecialties: (values: NurseSpecialty[]) => void;
};

const NurseSpecialtiesSelect = ({
  className,
  selectedNurseSpecialties,
  allNurseSpecialties,
  isLoading,
  onSetNurseSpecialties,
}: NurseSpecialtiesSelectProps) => {
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);

  const handleCheckChange = (nurseSpecialty: NurseSpecialty) => {
    const isAlreadyChecked = selectedNurseSpecialties.find((specialty) => specialty.id === nurseSpecialty.id);
    if (isAlreadyChecked) {
      onSetNurseSpecialties(selectedNurseSpecialties.filter((specialty) => specialty.id !== nurseSpecialty.id));
    } else {
      onSetNurseSpecialties([...selectedNurseSpecialties, nurseSpecialty]);
    }
  };

  useEffect(() => {
    if (isAllChecked) {
      onSetNurseSpecialties(allNurseSpecialties);
    } else {
      onSetNurseSpecialties([]);
    }
  }, [isAllChecked, allNurseSpecialties]);

  return (
    <div className={clsx("w-full", "flex flex-col items-start", className)}>
      <label className="w-full text-left text-sm text-examedi-gray-2">Especialidad HT</label>
      {isLoading && (
        <div
          className={clsx(
            "w-full max-h-48 py-1 px-2",
            "border border-examedi-gray-3",
            "rounded-md",
            "overflow-y-scroll",
          )}
        >
          <CheckboxesSkeletonLoader amount={6} />
        </div>
      )}
      {!isLoading && (
        <div className={clsx("w-full py-1 px-2", "border border-examedi-gray-3", "rounded-md")}>
          <label className="pt-1 flex justify-start gap-x-2 text-md">
            <input
              type="checkbox"
              checked={isAllChecked}
              onChange={() => setIsAllChecked(!isAllChecked)}
            />
            Todas
          </label>
          {allNurseSpecialties.map((nurseSpecialty) => (
            <div
              key={nurseSpecialty.id}
              className="w-full py-1"
            >
              <label className="flex justify-start gap-x-2">
                <input
                  type="checkbox"
                  checked={selectedNurseSpecialties.some((specialty) => specialty.id === nurseSpecialty.id)}
                  onChange={() => handleCheckChange(nurseSpecialty)}
                />
                {nurseSpecialty.name}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default NurseSpecialtiesSelect;
