/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useEffect, useMemo, useState } from "react";

import clsx from "clsx";
import { inRange } from "lodash";
import moment from "moment";

import AvailableNursesModal from "~/components/Capacity/AvailableNursesModal";
import HorizontalLoader from "~/components/Loaders/HorizontalLoader";
import useCancelableFetch from "~/hooks/useCancelableFetch";
import { getTimeblocksUnavailableItems } from "~/utils/api/v2/nurseBlocktables";
import sectorsAPI from "~/utils/api/v2/places";
import { getAvailabilitiesHeatmap } from "~/utils/api/v2/timeblocks";
import type { Locale } from "~/utils/interfaces/Locale";
import type { HeatmapNurse } from "~/utils/interfaces/Nurse";
import { BlockedItemsSector, TimeblockUnavailableItems } from "~/utils/interfaces/Timeblock";
import { capitalize } from "~/utils/texts";

const getCellColor = (value: number) => {
  if (value === 0) return "bg-[#fd8282]";
  else if (inRange(value, 1, 5)) return "bg-[#87DFFF]";
  else if (inRange(value, 5, 9)) return "bg-[#7EC4FD]";
  else if (inRange(value, 9, 13)) return "bg-[#46A5F5]";
  else if (inRange(value, 13, 17)) return "bg-[#305EC4]";
  else if (inRange(value, 17, 21)) return "bg-[#0000FF]";
  else if (inRange(value, 21, 24)) return "bg-[#2D00DF]";
  else return "bg-[#5000BF]";
};

type HeatmapProps = {
  country: Locale;
  sectorId: string | null;
  medicalActionIds?: string[];
  medicalServiceIds?: string[];
  nurseSpecialtiesIds?: string[];
  daysRange?: number;
  fingerprintAvailable: boolean | null;
  className?: string;
  filterByDate?: boolean;
  selectedDate?: string;
};

const Heatmap = ({
  country,
  sectorId,
  medicalActionIds,
  medicalServiceIds,
  nurseSpecialtiesIds,
  daysRange,
  fingerprintAvailable,
  filterByDate,
  selectedDate,
  className,
}: HeatmapProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<{
    nurses: HeatmapNurse[];
    date: string;
    time: string;
    sector: string | null;
  } | null>(null);
  const [timeblocksUnavailableItems, setTimeblocksUnavailableItems] = useState<TimeblockUnavailableItems | null>(null);
  const [blockedItemsBySector, setBlockedItemsBySector] = useState<BlockedItemsSector[] | null>(null);
  const args = useMemo(() => {
    return {
      sectorId,
      country,
      fingerprintAvailable,
      daysRange,
      medicalActionIds,
      medicalServiceIds,
      nurseSpecialtiesIds,
      filterByDate,
      selectedDate,
    };
  }, [
    country,
    daysRange,
    fingerprintAvailable,
    medicalActionIds,
    medicalServiceIds,
    sectorId,
    nurseSpecialtiesIds,
    filterByDate,
    selectedDate,
  ]);

  const {
    successResponse: currentHeatmap,
    isLoading,
    retry,
  } = useCancelableFetch(getAvailabilitiesHeatmap, args, true);

  const times = useMemo(() => {
    if (!currentHeatmap) return [];

    const heatmapTimes = Object.keys(Object.values(currentHeatmap)[0] as object);
    heatmapTimes.sort((a, b) => {
      if (moment(a, "HH:mm:ss").isBefore(moment(b, "HH:mm:ss"))) {
        return -1;
      }
      if (moment(a, "HH:mm:ss").isAfter(moment(b, "HH:mm:ss"))) {
        return 1;
      }
      return 0;
    });
    return heatmapTimes;
  }, [currentHeatmap]);

  const xAxisElements = useMemo(() => {
    if (!currentHeatmap) return [];

    if (filterByDate) {
      return Object.keys(currentHeatmap);
    }
    return Object.keys(currentHeatmap)
      .map((date) => ({ readableDate: moment(date).format("ddd DD"), date }))
      .map(({ readableDate, date }) => {
        const [day, number] = readableDate.split(" ");
        return { readableDate: `${capitalize(day)} ${number}`, date };
      });
  }, [currentHeatmap, filterByDate]);

  const handleDisplayNurseLinks = (
    nurses: HeatmapNurse[] | undefined,
    time: string,
    sector: string | null,
    date?: string,
  ) => {
    if (!nurses) return;
    setModalData({ nurses, date: (date || selectedDate)!, time, sector });
    setModalOpen(true);
  };

  const parseSectorName = (value: string) => {
    const separatorIndex = value.indexOf(" - ");
    let sector = value.slice(0, separatorIndex);
    const group = value.slice(separatorIndex + 3);

    // Handle MX names
    if (sector.startsWith("Zona")) {
      sector = sector.slice(5);
    }

    return { sector, group };
  };

  useEffect(() => {
    async function fetchUnavailableItems() {
      const unavailableItems = await getTimeblocksUnavailableItems({ countryCode: country });
      if ("data" in unavailableItems) setTimeblocksUnavailableItems(unavailableItems.data);
    }
    async function fetchBlockedItemsSector() {
      const blockedItemsBySector = await sectorsAPI.getBlockedItemsSector({
        sectorId: filterByDate ? "" : sectorId,
        countryCode: country,
      });
      if (blockedItemsBySector) setBlockedItemsBySector(blockedItemsBySector);
    }
    if (currentHeatmap) {
      fetchUnavailableItems();
      fetchBlockedItemsSector();
    }
  }, [currentHeatmap]);

  if (isLoading) {
    return (
      <div className="w-full py-5 flex items-center justify-center">
        <HorizontalLoader />
      </div>
    );
  }

  const fonasaByRegionText = (
    <>
      <p className="font-bold mb-0 mt-4">Para citas FONASA en regiones:</p>
      <ul className="list-disc text-sm ml-2">
        <li>Región de Antofagasta → NO se puede fonasa en ningún horario</li>
        <li>
          Región de Coquimbo y Región del Bio Bio → NO se puede agendar cita fonasa entre las 11:15 y 23:59 en la
          semana, y en ningún horario el fin de semana
        </li>
        <li>Región de Valparaíso → NO se puede agendar cita fonasa entre las 11:15 y 23:59 ningún día</li>
      </ul>
    </>
  );

  const searchButton = (
    <button
      className={clsx(
        "w-fit mt-8 px-3.5 py-2.5",
        "text-center text-examedi-white-pure",
        "bg-examedi-blue-strong hover:bg-examedi-blue-strong-50",
        "rounded-md",
      )}
      onClick={() => retry(args)}
      disabled={isLoading}
    >
      Buscar disponibilidades
    </button>
  );

  if (!currentHeatmap) {
    return <>{searchButton}</>;
  }
  /**
   * This configuration returns a heatmap where the Y-axis is composed by ordered times
   * and the X-axis is composed by sector names in the format "Name - Group"
   */
  if (filterByDate) {
    return (
      <>
        {searchButton}
        {country === "cl" && fonasaByRegionText}
        <div className={clsx("w-full", "mt-4", "flex gap-x-1", className)}>
          <div className="h-full w-40 flex flex-col gap-y-1">
            {[null, ...times].map((time) => (
              <div
                key={`fixed-column-${time}`}
                className={clsx("w-full h-12", "flex items-center justify-center", "bg-examedi-blue-strong-50")}
              >
                {time}
              </div>
            ))}
          </div>
          <div className={clsx("w-full", "overflow-x-scroll", "flex flex-col gap-y-1")}>
            {[null, ...times].map((time) => (
              <div
                key={time}
                className="w-fit flex gap-x-1"
              >
                {xAxisElements.map((sectorName) => {
                  const { sector, group } = parseSectorName(sectorName);
                  return (
                    <div
                      key={sectorName as string}
                      className={clsx(
                        "w-40 h-12",
                        "flex items-center justify-center",
                        "cursor-pointer",
                        time === null && ["bg-examedi-blue-strong-50", "!justify-start"],
                        time !== null && [
                          getCellColor(currentHeatmap[sectorName]![time]!.length),
                          "!text-examedi-white-pure",
                        ],
                      )}
                      onClick={() =>
                        time !== null && handleDisplayNurseLinks(currentHeatmap[sectorName]![time], time, sectorName)
                      }
                    >
                      {time === null && !!filterByDate && (
                        <div className="px-2 flex flex-col text-sm">
                          <span className="line-clamp-1">{sector}</span>
                          <span>{group}</span>
                        </div>
                      )}
                      {time !== null && currentHeatmap[sectorName]![time]!.length}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
          {modalData !== null && (
            <AvailableNursesModal
              isOpen={modalOpen}
              onSetIsOpen={(value: boolean) => setModalOpen(value)}
              timeblocksUnavailableItems={timeblocksUnavailableItems}
              blockedItemsBySector={blockedItemsBySector}
              {...modalData}
            />
          )}
        </div>
      </>
    );
  }

  return (
    <>
      {searchButton}
      {country === "cl" && fonasaByRegionText}
      <div className={clsx("w-full", "mt-4", "flex gap-x-1", className)}>
        <div className="h-full w-40 flex flex-col gap-y-1">
          {[null, ...times].map((time) => (
            <div
              key={`fixed-column-${time}`}
              className={clsx("w-full h-12", "flex items-center justify-center", "bg-examedi-blue-strong-50")}
            >
              {time}
            </div>
          ))}
        </div>
        <div className={clsx("w-full", "overflow-x-scroll", "flex flex-col gap-y-1")}>
          {[null, ...times].map((time) => (
            <div
              key={time}
              className="w-fit flex gap-x-1"
            >
              {xAxisElements.map(({ readableDate, date }) => (
                <div
                  key={`${date} - ${time}`}
                  className={clsx(
                    "w-40 h-12",
                    "flex items-center justify-center",
                    "cursor-pointer",
                    time === null && "bg-examedi-blue-strong-50",
                    time !== null && [getCellColor(currentHeatmap[date]![time]!.length), "!text-examedi-white-pure"],
                  )}
                  onClick={() =>
                    time !== null && handleDisplayNurseLinks(currentHeatmap[date]![time], time, sectorId, date)
                  }
                >
                  {time === null && !filterByDate && readableDate}
                  {time !== null && currentHeatmap[date]![time]!.length}
                </div>
              ))}
            </div>
          ))}
        </div>
        {modalData !== null && (
          <AvailableNursesModal
            isOpen={modalOpen}
            onSetIsOpen={(value: boolean) => setModalOpen(value)}
            timeblocksUnavailableItems={timeblocksUnavailableItems}
            blockedItemsBySector={blockedItemsBySector}
            {...modalData}
          />
        )}
      </div>
    </>
  );
};

export default Heatmap;
