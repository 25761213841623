import React from "react";

import clsx from "clsx";

type FingerprintAvailableRadioProps = {
  selectedOption: string;
  options: string[];
  className?: string;
  disabled?: boolean;
  onSetOption: (value: string) => void;
};

const FingerprintAvailableRadio = ({
  selectedOption,
  options,
  disabled,
  className,
  onSetOption,
}: FingerprintAvailableRadioProps) => {
  return (
    <div className={clsx("w-full", "flex flex-col items-start", className)}>
      <label className="w-full text-left text-sm text-examedi-gray-2">Tiene Huellero</label>
      <div className={clsx("w-full py-1", "border border-examedi-gray-3", "rounded-md")}>
        {options.map((option) => (
          <div
            key={option}
            className="w-full p-1"
          >
            <label className="flex justify-start gap-x-2">
              <input
                type="radio"
                value={option}
                checked={selectedOption === option}
                onChange={() => onSetOption(option)}
                disabled={!!disabled}
              />
              {option}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FingerprintAvailableRadio;
