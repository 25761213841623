import { decamelizeKeys } from "humps";
import qs from "qs";

import api from "~/utils/api/api";
import type { RequestResponse, RequestError } from "~/utils/interfaces/request";
import type { TimeblockUnavailableItems } from "~/utils/interfaces/Timeblock";

export async function getTimeblocksUnavailableItems({
  countryCode,
}: {
  countryCode: string;
}): Promise<RequestResponse<TimeblockUnavailableItems>> {
  try {
    const data = decamelizeKeys({ countryCode });
    const res = await api.get<TimeblockUnavailableItems>(
      `/v2/time-availability/timeblocks-unavailable-items/?${qs.stringify(data)}`,
    );
    return { data: res.data };
  } catch (error) {
    return { error } as RequestError;
  }
}
