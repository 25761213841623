import React from "react";

import clsx from "clsx";

type SelectProps<T> = {
  options: T[];
  displayDataIndex?: string;
  selected: T | undefined;
  label?: string;
  placeholder: string;
  className?: string;
  labelClassName?: string;
  parser?: (value: string) => string;
  onSetSelected: (value: T) => void;
};

const Select = <T,>({
  options,
  displayDataIndex,
  selected,
  label,
  labelClassName,
  placeholder,
  className,
  parser,
  onSetSelected,
}: SelectProps<T>) => {
  return (
    <div className={clsx("w-full", "flex flex-col items-start", "gap-y-1", className)}>
      {!!label && (
        <label className={clsx("w-full text-left text-sm text-examedi-gray-2", labelClassName)}>{label}</label>
      )}
      <select
        value={selected as string}
        onChange={(e) => onSetSelected(e.target.value as T)}
        placeholder={placeholder}
        className={clsx("w-full px-2 py-2.5", "rounded-md", "border border-examedi-gray-4")}
      >
        {options.map((option) => {
          const opt = (displayDataIndex ? option[displayDataIndex] : option) as string;
          return (
            <option
              key={opt}
              value={opt}
            >
              {parser?.(opt) || opt}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default Select;
