import React, { useEffect, useMemo, useState } from "react";

import clsx from "clsx";

import { CheckboxesSkeletonLoader } from "~/components/Common/SkeletonLoader";
import type { Item } from "~/utils/interfaces/Services";

type MedicalServicesSelectProps = {
  className?: string;
  selectedMedicalServices: Item[];
  allMedicalServices: Item[];
  isLoading: boolean;
  onSetMedicalServices: (values: Item[]) => void;
};

const MedicalServicesSelect = ({
  className,
  selectedMedicalServices,
  allMedicalServices,
  isLoading,
  onSetMedicalServices,
}: MedicalServicesSelectProps) => {
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);

  const sortedElements = useMemo(() => {
    const arrayCopy = [...allMedicalServices];
    arrayCopy.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: "base" }));
    return arrayCopy;
  }, [allMedicalServices]);

  const handleCheckChange = (medicalService: Item) => {
    const isAlreadyChecked = selectedMedicalServices.findIndex((ma) => ma.id === medicalService.id) > -1;
    if (isAlreadyChecked) {
      onSetMedicalServices(selectedMedicalServices.filter((ma) => ma.id !== medicalService.id));
    } else {
      onSetMedicalServices([...selectedMedicalServices, medicalService]);
    }
  };

  useEffect(() => {
    if (isAllChecked) {
      onSetMedicalServices(allMedicalServices);
    } else {
      onSetMedicalServices([]);
    }
  }, [isAllChecked, allMedicalServices]);

  return (
    <div className={clsx("w-full", "flex flex-col items-start", className)}>
      <label className="w-full text-left text-sm text-examedi-gray-2">Servicios médicos</label>
      {isLoading && (
        <div
          className={clsx(
            "w-full max-h-48 py-1 px-2",
            "border border-examedi-gray-3",
            "rounded-md",
            "overflow-y-scroll",
          )}
        >
          <CheckboxesSkeletonLoader amount={7} />
        </div>
      )}
      {!isLoading && (
        <div
          className={clsx(
            "w-full max-h-48 py-1 px-2",
            "border border-examedi-gray-3",
            "rounded-md",
            "overflow-y-scroll",
          )}
        >
          <label className="pt-1 flex justify-start gap-x-2 text-md">
            <input
              type="checkbox"
              checked={isAllChecked}
              onChange={() => setIsAllChecked(!isAllChecked)}
            />
            Todos
          </label>
          {sortedElements.map((medicalService) => (
            <div
              key={medicalService.id}
              className="w-full py-1"
            >
              <label className="flex justify-start gap-x-2">
                <input
                  type="checkbox"
                  checked={selectedMedicalServices.some((ma) => ma.id === medicalService.id)}
                  onChange={() => handleCheckChange(medicalService)}
                />
                {medicalService.name}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MedicalServicesSelect;
