import React, { useEffect, useState } from "react";

import Modal from "~/components/Common/Modal";
import { getDayName } from "~/utils/dates/formatDate";
import type { HeatmapNurse } from "~/utils/interfaces/Nurse";
import { BlockedItemsSector, TimeblockUnavailableItems } from "~/utils/interfaces/Timeblock";

type AvailableNursesModalProps = {
  nurses: HeatmapNurse[];
  date: string;
  time: string;
  timeblocksUnavailableItems: TimeblockUnavailableItems | null;
  blockedItemsBySector: BlockedItemsSector[] | null;
  sector: string | null;
  isOpen: boolean;
  onSetIsOpen: (value: boolean) => void;
};

const AvailableNursesModal = ({
  nurses,
  date,
  time,
  timeblocksUnavailableItems,
  blockedItemsBySector,
  sector,
  isOpen,
  onSetIsOpen,
}: AvailableNursesModalProps) => {
  const [unavailableItemsByTimeblock, setUnavailableItemsByTimeblock] = useState<string[]>([]);
  const [unavailableItemsBySector, setUnavailableItemsBySector] = useState<string[]>([]);

  function searchUnavailableItems() {
    const dayName = getDayName(date, "en-US");
    if (!timeblocksUnavailableItems) return;
    try {
      const items = timeblocksUnavailableItems[dayName][time];
      if (items.length > 0) setUnavailableItemsByTimeblock(items);
    } catch {
      setUnavailableItemsByTimeblock([]);
    }
  }

  function searchBlockedItemsBySector() {
    const sectorBlockedItems = blockedItemsBySector?.find(
      (elem) => elem.sectorId === sector || `${elem.sectorName} - ${elem.sectorGroup}` === sector,
    );

    if (sectorBlockedItems) {
      setUnavailableItemsBySector(sectorBlockedItems.blockedItems);
    } else setUnavailableItemsBySector([]);
  }

  useEffect(() => {
    searchUnavailableItems();
    searchBlockedItemsBySector();
  }, [isOpen]);

  return (
    <Modal
      header={
        <p className="font-medium">
          {date} - {time}
        </p>
      }
      content={
        <div className="overflow-y-scroll max-h-96">
          {unavailableItemsByTimeblock.length > 0 && (
            <div className="text-base">
              <p className="font-bold text-md mb-1">Servicios NO disponibles en este horario</p>
              <ul className="list-disc">
                {unavailableItemsByTimeblock.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          )}
          {unavailableItemsBySector.length > 0 && (
            <div className="text-base">
              <p className="font-bold text-md mb-1">Servicios NO disponibles en este sector</p>
              <ul className="list-disc">
                {unavailableItemsBySector.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          )}
          <p className="font-bold text-md mb-1">Health Team Disponible</p>
          <ul className="w-full flex flex-col gap-y-3 list-disc">
            {nurses.map((nurse) => (
              <li key={nurse.id}>
                <a
                  href={`/health-team/${nurse.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {nurse.fullname}
                </a>
                {nurse.blocked_items && nurse.blocked_items?.length > 0 && (
                  <div className="text-sm">
                    <p className="mb-1">Servicios del HT bloqueados (no necesariamente en el día seleccionado):</p>
                    <ul className="list-disc">
                      {nurse.blocked_items.map((blocked, idx) => (
                        <li key={idx}>
                          {blocked.item} bloqueado desde el {blocked.begin_date} hasta el {blocked.end_date}{" "}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            ))}
            {!nurses.length && <div className="font-light">No hay HTs disponibles a esta hora...</div>}
          </ul>
        </div>
      }
      isOpen={isOpen}
      onSetIsOpen={() => onSetIsOpen(false)}
    />
  );
};

export default AvailableNursesModal;
