import React, { useEffect, useState } from "react";

import clsx from "clsx";

import { CheckboxesSkeletonLoader } from "~/components/Common/SkeletonLoader";
import type { MedicalAction } from "~/utils/interfaces/Services";

type MedicalActionsSelectProps = {
  className?: string;
  selectedMedicalActions: MedicalAction[];
  allMedicalActions: MedicalAction[];
  isLoading?: boolean;
  onSetMedicalActions: (values: MedicalAction[]) => void;
};

const MedicalActionsSelect = ({
  className,
  selectedMedicalActions,
  allMedicalActions,
  isLoading,
  onSetMedicalActions,
}: MedicalActionsSelectProps) => {
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);

  const handleCheckChange = (medicalAction: MedicalAction) => {
    const isAlreadyChecked = selectedMedicalActions.findIndex((ma) => ma.id === medicalAction.id) > -1;
    if (isAlreadyChecked) {
      onSetMedicalActions(selectedMedicalActions.filter((ma) => ma.id !== medicalAction.id));
    } else {
      onSetMedicalActions([...selectedMedicalActions, medicalAction]);
    }
  };

  useEffect(() => {
    if (isAllChecked) {
      onSetMedicalActions(allMedicalActions);
    } else {
      onSetMedicalActions([]);
    }
  }, [isAllChecked, allMedicalActions]);

  return (
    <div className={clsx("w-full", "flex flex-col items-start", className)}>
      <label className="w-full text-left text-sm text-examedi-gray-2">Acciones médicas</label>
      {isLoading && (
        <div
          className={clsx(
            "w-full max-h-48 py-1 px-2",
            "border border-examedi-gray-3",
            "rounded-md",
            "overflow-y-scroll",
          )}
        >
          <CheckboxesSkeletonLoader amount={6} />
        </div>
      )}
      {!isLoading && (
        <div className={clsx("w-full py-1 px-2", "border border-examedi-gray-3", "rounded-md")}>
          <label className="pt-1 flex justify-start gap-x-2 text-md">
            <input
              type="checkbox"
              checked={isAllChecked}
              onChange={() => setIsAllChecked(!isAllChecked)}
            />
            Todas
          </label>
          {allMedicalActions.map((medicalAction) => (
            <div
              key={medicalAction.id}
              className="w-full py-1"
            >
              <label className="flex justify-start gap-x-2">
                <input
                  type="checkbox"
                  checked={selectedMedicalActions.some((ma) => ma.id === medicalAction.id)}
                  onChange={() => handleCheckChange(medicalAction)}
                />
                {medicalAction.name}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MedicalActionsSelect;
