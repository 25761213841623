import React from "react";

import Select from "~/components/Common/Select";
import { LOCALITY_CONFIG } from "~/utils/constants/sectors";
import type { Locale } from "~/utils/interfaces/Locale";

type LandmarkSelectProps = {
  country: Locale;
  currentRegion: string;
  className?: string;
  onSetRegion: (value: Locale) => void;
};

const LandmarkSelect = ({ country, currentRegion, className, onSetRegion }: LandmarkSelectProps) => {
  const regionNames = Object.entries(LOCALITY_CONFIG[country]).map(([key, value]) => value?.displayName || key);

  const handleOnSelect = (region: string) => {
    if (!Object.keys(LOCALITY_CONFIG[country]).includes(region)) {
      const foundObject = Object.entries(LOCALITY_CONFIG[country]).find(
        ([key, value]) => value?.displayName === region,
      );
      if (foundObject) {
        onSetRegion(foundObject[0] as Locale);
        return;
      }
    }
    onSetRegion(region as Locale);
  };

  return (
    <Select
      options={regionNames}
      selected={currentRegion}
      label="Región"
      placeholder="Selecciona una región"
      className={className}
      onSetSelected={handleOnSelect}
    />
  );
};

export default LandmarkSelect;
